
import { Card, Typography } from "antd";
import { useEffect, useState } from "react";


const { Paragraph } = Typography;

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const userDataString = sessionStorage.getItem('userData');
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);
      setUserData(userDataObject);
    }
  }, []);

  return (
<div className="bg-gradient-to-br from-gray-100 via-gray-50 to-white min-h-[800px] flex justify-center items-start rounded-xl shadow-md">
  <div className="w-full  p-8 ">
    {/* Welcome Header */}
    <h1 className="text-3xl font-bold text-center bg-blue-200 text-blue-800 p-4 mb-8 rounded-2xl shadow-sm">
      Welcome to {userData?.clinic_name || "Our"} Patient Portal
    </h1>

    {/* Information Card */}
    <Card className="p-6 rounded-3xl border border-gray-200 shadow-md">
      <div className="text-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">
          Your Personalized Healthcare Hub
        </h2>
        <p className="text-gray-600">
          Access all your medical reports, track your vitals, and stay informed with ease.
        </p>
      </div>

      {/* Paragraphs */}
      <div className="space-y-4">
        <Paragraph className="text-lg text-gray-700 leading-relaxed">
          Welcome to your personal patient portal. Here, you'll access all your medical reports and receive updates on basic healthcare vitals from your provider.
        </Paragraph>
        <Paragraph className="text-lg text-gray-700 leading-relaxed">
          Take advantage of this secure online service provided by your healthcare provider, with the assurance that your personal information is encrypted and stored securely.
        </Paragraph>
        <Paragraph className="text-lg font-semibold text-red-600 leading-relaxed">
          ⚠️ Remember! In case of a life-threatening emergency, always call your local emergency services (e.g., 911 in the U.S.).
        </Paragraph>
        <Paragraph className="text-lg text-gray-700 leading-relaxed">
          Below are some helpful tips to navigate the portal:
        </Paragraph>
      </div>

      {/* List of Features */}
      <ul className="list-disc pl-6 space-y-3 mt-4">
        <li className="text-lg text-gray-700">
          Click on the <strong>"Basic Vitals"</strong> tab to view the latest updates on your vitals.
        </li>
        <li className="text-lg text-gray-700">
          Access your most recent prescriptions via the <strong>"Prescription"</strong> tab on the dashboard.
        </li>
        <li className="text-lg text-gray-700">
          View all your requested lab tests under the <strong>"Ordered Labs"</strong> section.
        </li>
        <li className="text-lg text-gray-700">
          Check out your recent lab results in the <strong>"Lab Results"</strong> tab on the dashboard.
        </li>
      </ul>
    </Card>
  </div>
</div>

  );
};

export default Dashboard;
