import { DateTime } from "luxon";
import { FormattedNumber } from "react-intl";
let locale = "en-US";

export const dateFormat = (date_time) => {

    const dateObj = new Date(date_time);
  
    const localDate = dateObj.toLocaleDateString(locale, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  
    return localDate;
  };



export const formatTime = (date, timeFormat, timeZone) => {
  return  DateTime.fromISO(date, { zone: 'UTC' }).setZone(timeZone).toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: timeFormat });
};



export const formatDate = (date, dateFormat, timeZone)=>{
  return DateTime.fromISO(date, { zone: 'UTC' }).setZone(timeZone).setLocale(dateFormat).toLocaleString()
}

export const formatDateTime = (date, dateFormat, timeFormat, timeZone) => {
  const formattedDate = formatDate(date, dateFormat, timeZone);
  const formattedTime = formatTime(date, timeFormat, timeZone);
  return `${formattedDate}, ${formattedTime}`;
};


export const Currency = ({ value }) => {
  const userDataString = sessionStorage.getItem('userData');
  const isData = JSON.parse(userDataString);
  const isCurrency = isData?.local_currency || "USD";
  return (
    <FormattedNumber value={value} style="currency" currency={isCurrency} />
  );
};


