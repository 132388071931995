import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Tooltip } from "antd";
import axios from "axios";
import ViewLabResults from "./components/ViewLabResults";
import DateTimeFormat from "../../components/DateTimeFormat/DateTimeFormat";

const { Title } = Typography;



const LabResults = () => {
  const [userData, setUserData] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [fileLoading, setFileLoading] = useState(false)
  const API_Path = process.env.REACT_APP_API_URL || 'http://localhost:8000';
  const [view, setView] = useState({
    viewState: false,
    viewData: [],
  });

  useEffect(() => {
    const userDataString = sessionStorage.getItem('userData');
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);
      if (userDataObject.lab_uploaded_results) {
        userDataObject.lab_uploaded_results.sort((a, b) => new Date(b.date_time) - new Date(a.date_time));
      }
      setUserData(userDataObject);
    }
  }, []);

  const coloumns = [
    {
      key: "#",
      title: "#",
      render: (_, __, index) => index + 1,
      width: "5%"
    },
    {
      key: 1,
      title: "Lab Order ID",
      dataIndex: "lab_order_id",
      width: '15%',
    },
    {
      key: 2,
      title: "Service Name",
      dataIndex: "service_name",
      ellipsis: true
    },
    // {
    //   key: 3,
    //   title: "Description",
    //   dataIndex: "description",
    //   ellipsis: true
    // },
    {
      key: 4,
      title: "Uploaded By",
      dataIndex: "added_by",
      width:'20%',
      align:'center',
    },
    {
      key: 5,
      title: "Date",
      dataIndex: "date_time",
      align:'center',
      width:"10%",
      sorter: (a, b) => {
        const dateA = new Date(a.date_time);
        const dateB = new Date(b.date_time);
        return dateA - dateB;
      },
      render: (text) => (
        <Tooltip title={<DateTimeFormat date={text} type="time" />}>
        <span>{text ? <DateTimeFormat date={text} type="date" /> : ""}</span>
      </Tooltip>
      ),
    },
    {
      key: 6,
      title: "Action",
      align: "center",
      width: "10%",
      render: (text, record) => (
        <Button type='primary' size="small" onClick={()=>handleView(record)}>View Results</Button>
      ),
    },
  ];

  const handleView = async (record) => {
    await handleFile(record?.uploaded_file_encrypted_name);
    setView({
      ...view,
      viewState: true,
      viewData: record,
    });
  };

  // http://127.0.0.1:8000/patient/view-file/?filename=190a5af4-bc86-4665-9373-f03a00a91643&mrn_number=lib-22-AD-1002&password=2fdac91b5cb639
  const handleFile = async (record) => {
   setFileLoading(true)
    try {
      const response = await axios.get(
        `${API_Path}/patient/view-file/?filename=${record}&mrn_number=${userData?.patient_info?.mrn_number}&password=${userData?.patient_info?.password}`
      );
      if (response.status === 200) {
        const fileContent = response.data;
        setFileData(fileContent);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFileLoading(false)
    }
  };

  const closeView = ()=>{
    setView({
      ...view,
      viewState: false,
      viewData: []
    })
  }

  return (
    <>
      <Title level={4} className="mb-3">
        Lab Results
      </Title>
      <Table
        columns={coloumns}
        dataSource={userData?.lab_uploaded_results || []}
        size="small"
        pagination={false}
      />
      <ViewLabResults visible={view?.viewState} onClose={closeView} data={view?.viewData} fileData={fileData} loading={fileLoading}/>
    </>
  );
};

export default LabResults;
