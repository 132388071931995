import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ViewTemplate = ({ template }) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    // Trigger a re-render or re-initialization
    setKey((prevKey) => prevKey + 1);
  }, [template]);

  return (
    <div
      className='mt-2'
    >
    <ReactQuill
      key={key}
      value={template}
      readOnly={true}
      theme="snow"
      modules={{ toolbar: false }} // Disable toolbar in read-only mode
    />
    </div>
  );
};

export default ViewTemplate;
