import React from "react";
import { formatDate, formatDateTime, formatTime } from "../../utils/Utils";

const DateTimeFormat = ({ date, type = "date" }) => {
  const userDataString = sessionStorage.getItem("userData");
  const isData = JSON.parse(userDataString);

  const officeDateFormat = isData?.date_format;
  const officeTimeFormat = isData?.time_format;
  const officeTimezone = isData?.facility_time_zone;

  if (!date) return null;

  const formattedDateTime = {
    date: formatDate(date, officeDateFormat, officeTimezone),
    time: formatTime(date, officeTimeFormat, officeTimezone),
    datetime: formatDateTime(
      date,
      officeDateFormat,
      officeTimeFormat,
      officeTimezone
    ),
  };

  return <>{formattedDateTime[type]}</>;
};

export default DateTimeFormat;
