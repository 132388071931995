import React from 'react';
import dayjs from 'dayjs';
// import advancedFormat from 'dayjs/plugin/advancedFormat';

// // Extend dayjs with advancedFormat
// dayjs.extend(advancedFormat);

const DateFormat = ({ date }) => {
    const userDataString = sessionStorage.getItem("userData");
    const isData = JSON.parse(userDataString);

  if (!date) return null;

  // Determine the date format from office settings
  const officeDateFormat = isData?.date_format === 'en-US' 
    ? 'MM/DD/YYYY' 
    : isData?.date_format === 'en-GB' 
      ? 'DD/MM/YYYY' 
      : 'YYYY/MM/DD';

  const formattedDate = dayjs(date).format(officeDateFormat);

  return <>{formattedDate}</>;
};

export default DateFormat;
