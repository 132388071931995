import { Button, Modal } from "antd";
import React from "react";
import DateFormat from "../../../components/DateTimeFormat/DateFormat";

const AllergiesModal = ({ open, selectedItem, onClose }) => {
  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      title="Allergy And Reaction"
      open={open}
      onCancel={handleCancel}
      footer={null}
      width={450}
      closeIcon={
        <Button size="small" className="app-close-icon" shape="round">
          Close
        </Button>
      }
    >
      {selectedItem && (
        <>
          <p>
            <span className="font-medium">Start Date:</span>
            <span className="ml-2">
              {selectedItem?.start_date
                ? <DateFormat date={selectedItem?.start_date}/>
                : "No Date Entered"}
            </span>
          </p>
          <p>
            <span className="font-medium">End Date:</span>
            <span className="ml-2">
              {selectedItem?.end_date
                ? <DateFormat date={selectedItem?.end_date}/>
                : "No Date Entered"}
            </span>
          </p>
          <p>
            <span className="font-medium">Allergy Name:</span>
            <span className="ml-2">{selectedItem?.allergy_value}</span>
          </p>

          <p>
            <span className="font-medium">Reaction:</span>
            <span className="ml-2">{selectedItem?.description}</span>
          </p>

          <p>
            <span className="font-medium">Added By:</span>
            <span className="ml-2">{selectedItem?.added_by}</span>
          </p>
        </>
      )}
    </Modal>
  );
};

export default AllergiesModal;
