import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

const locale = 'en'; 
const messages = {}; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <IntlProvider locale={locale} messages={messages}>
    <App />
    </IntlProvider>
    </BrowserRouter>
  </React.StrictMode>
);

