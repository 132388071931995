import { Card } from "antd";
import React, { useEffect, useState } from "react";
import AllergiesModal from "./components/AllergiesModal";

const RecordedAllergies = () => {
    const [userData, setUserData] = useState(null);
    const [showAllergiesModal, setShowAllergiesModal] = useState({});

    const handleAllergyModal = (record) => {
        setShowAllergiesModal({
          open: true,
          selectedItem: record,
        });
      };
    
      const handleCancel = () => {
        setShowAllergiesModal(null);
      };

    const drugAllergies = userData?.allergies?.filter((item) => item?.drug);
    const foodAllergies = userData?.allergies?.filter((item) => item?.food);
    const environmentAllergies = userData?.allergies?.filter(
      (item) => item?.environment
    );
    const otherAllergies = userData?.allergies?.filter((item) => item?.other);
  
    const allergyList = [
      { type: "drug", allergies: drugAllergies },
      { type: "food", allergies: foodAllergies },
      { type: "environment", allergies: environmentAllergies },
      { type: "other", allergies: otherAllergies },
    ];


    useEffect(() => {
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
          const userDataObject = JSON.parse(userDataString);
          setUserData(userDataObject);
        }
      }, []);


  return (
    <>
<Card className="shadow-lg rounded-3xl bg-white p-6">
  {allergyList?.map(({ type, allergies }) => (
    <div key={type} className="mb-6">
      <p className="font-semibold text-lg text-gray-800 mb-2 border-b border-gray-200">
        {`${type.charAt(0).toUpperCase() + type.slice(1)} Allergies:`}
      </p>

      <ul className="space-y-2">
        {allergies?.map((allergy) => (
          <li
            key={allergy?.id}
            className="flex items-center group cursor-pointer"
          >
            <span
              className="text-blue-600 hover:text-blue-800  decoration-blue-400 transition-colors"
              onClick={() => handleAllergyModal(allergy)}
            >
              {allergy?.allergy_value}
            </span>
          </li>
        ))}
      </ul>
    </div>
  ))}
</Card>


      <AllergiesModal
        open={showAllergiesModal?.open}
        selectedItem={showAllergiesModal?.selectedItem}
        onClose={handleCancel}
      />
    </>
  );
};

export default RecordedAllergies;
