import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import RecordedVitals from "./RecordedVitals";
import RecordedAllergies from "./RecordedAllergies";
import { FaHeartbeat } from "react-icons/fa";
import { FaStethoscope } from "react-icons/fa";
import { TbDropCircle } from "react-icons/tb";
import { FaTemperatureHalf } from "react-icons/fa6";
import { formatDate } from "../../utils/Utils";

const VitalSigns = () => {
  const [userData, setUserData] = useState(null);
  const [latestVitals, setLatestVitals] = useState({
    heart_rate: null,
    blood_pressure: null,
    oxygen: null,
    body_temp: null,
    date_added: null
  });

  useEffect(() => {
    const userDataString = sessionStorage.getItem("userData");
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);
      if (userDataObject && userDataObject.vitals && userDataObject.vitals.length > 0) {
        const latestUserData = userDataObject.vitals[userDataObject.vitals.length - 1];
        setLatestVitals({
          heart_rate: latestUserData.heart_rate,
          blood_pressure: latestUserData.blood_pressure,
          oxygen: latestUserData.oxygen,
          body_temp: latestUserData.body_temp,
          date_added: latestUserData.date_time
        });
      }
      setUserData(userDataObject);
    }
  }, []);
  

  const vitals = [
    {
      name: "Heart Rate",
      value: latestVitals?.heart_rate || "No Record Added",
      icon: <FaHeartbeat />,
      color: "#FF4D4F",
    },
    {
      name: "Blood Pressure",
      value: latestVitals?.blood_pressure || "No Record Added",
      icon: <FaStethoscope />,
      color: "#1890FF",
    },
    {
      name: "Oxygen Saturation",
      value: latestVitals?.oxygen ? (latestVitals?.oxygen >= 95 ? <p className="text-green-500">{latestVitals?.oxygen}%</p> :
              latestVitals?.oxygen < 95 && latestVitals?.oxygen > 92 ? <p className="text-yellow-400">{latestVitals?.oxygen}%</p> :
              latestVitals?.oxygen < 93 ? <p className="text-red-500">{latestVitals?.oxygen}%</p> : <p>{latestVitals?.oxygen}%</p>): "No Record Added"
      ,
      icon: <TbDropCircle />,
      color: "#52C41A",
    },
    {
      name: "Temperature",
      value: latestVitals?.body_temp ? (latestVitals?.body_temp >= 100 && latestVitals?.body_temp <= 110
        ? <p className="text-red-500">{latestVitals?.body_temp}°F</p>
        : latestVitals?.body_temp >= 90 && latestVitals?.body_temp <= 100
        ? <p className="text-green-500">{latestVitals?.body_temp}°F</p>
        : latestVitals?.body_temp >= 30 && latestVitals?.body_temp < 40
        ? <p className="text-green-500">{latestVitals?.body_temp}°C</p>
        : latestVitals?.body_temp >= 40 && latestVitals?.body_temp <= 45
        ? <p className="text-red-500">{latestVitals?.body_temp}°C</p>
        : <p>{latestVitals?.body_temp}°</p>) : "No Record Added",
      icon: <FaTemperatureHalf />,
      color: "#FADB14",
    }
    
  ];

  return (
    <>
      <h1 className="text-3xl font-semibold p-3 mb-2 rounded-2xl">
        Welcome back, {userData?.patient_info?.first_name}{" "}
        {userData?.patient_info?.middle_name && userData?.patient_info?.middle_name}{" "}
        {userData?.patient_info?.last_name}
      </h1>
      <h1 className="text-2xl font-semibold text-center bg-blue-100 p-3 mb-6 rounded-2xl">
        Recorded Vitals
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      {vitals.map((vital, index) => (
  <div key={index} className="w-full p-4">
    <Card
      className="text-center border border-gray-200 shadow-lg rounded-3xl transition-transform transform hover:scale-105 hover:shadow-2xl hover:border-gray-300 hover:bg-gradient-to-r from-blue-50 to-indigo-100"
      bodyStyle={{ padding: "24px" }}
    >
      <div
        className="flex justify-center items-center mb-4 w-16 h-16 rounded-full"
        style={{
          backgroundColor: `${vital.color}20`, // Lighter background with the vital's color
          color: vital.color,
        }}
      >
        <div className="text-3xl">{vital.icon}</div>
      </div>
      <p className="text-xl font-bold mb-2 text-gray-700">{vital.name}</p>
      <p className="text-2xl font-semibold text-gray-900">{vital.value}</p>
    </Card>
  </div>
))}

      </div>
      <div className="flex justify-between items-center bg-gray-50 p-6 rounded-xl shadow-md">
  <h2 className="text-gray-700 font-medium text-lg">
    These are your recorded vitals from your most recent visit.
  </h2>
  <h2 className="text-gray-500 font-medium text-lg">
    {latestVitals?.date_added && (
      <span className="italic text-sm">
        Last Updated: {formatDate(latestVitals?.date_added)}
      </span>
    )}
  </h2>
</div>

<Row gutter={[16, 16]} className="mt-6">
  <Col sm={24} md={12} lg={12}>
    <div className="bg-blue-50 shadow-md rounded-2xl p-6 hover:shadow-lg transition-shadow duration-300">
      <h1 className="text-2xl font-semibold text-center text-blue-800 mb-4">
        Historical Vitals
      </h1>
      <hr className="border-t border-blue-200 mb-6" />
      <RecordedVitals />
    </div>
  </Col>
  <Col sm={24} md={12} lg={12}>
    <div className="bg-blue-50 shadow-md rounded-2xl p-6 hover:shadow-lg transition-shadow duration-300">
      <h1 className="text-2xl font-semibold text-center text-blue-800 mb-4">
        Recorded Allergies
      </h1>
      <hr className="border-t border-blue-200 mb-6" />
      <RecordedAllergies />
    </div>
  </Col>
</Row>

    </>
  );
};

export default VitalSigns;
